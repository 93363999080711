export default function toggleMobileMenu() {
  $(document).ready(function() {
    $('#toggleMenuMobile').click(function() {
      $('#menuMobile').toggleClass('isActive')

      this.classList.toggle('isActive')
      $('.menu--icon').toggleClass('hidden')
      $('.close--icon').toggleClass('hidden')

    });
  })
}

toggleMobileMenu()

